<template>
    <v-container>
        <v-card class="mb-10">
            <v-card-title class="card-title">League Form</v-card-title>
        </v-card>
        <v-form :readonly="readOnly">
            <v-row>
                <v-col cols="6">
                    <v-switch
                        v-model="league.active"
                        hide-details
                        inset
                        :label="league.active ? 'Active' : 'Not Active'"
                    />
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        v-model="league.title"
                        label="Title"
                        type="text"
                        :clearable="clearable"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <date-time-picker
                        :date-label="'From Date'"
                        :date="startDate"
                        @setDate="setStartDate"
                    />
                </v-col>
                <v-col cols="6">
                    <date-time-picker
                        :date-label="'To Date'"
                        :date="endDate"
                        @setDate="setEndDate"
                    />
                </v-col>
            </v-row>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="mx-2 white--text" :disabled="shouldDisableSubmit()" :loading="loading" large color="black" @click="onSubmit">
                Submit
            </v-btn>
            <v-btn class="mx-2 white--text" large color="red" @click="$router.go(-1)">
                Cancel
            </v-btn>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import leagueService from '@/services/league'
    import Alert from '@/components/Alert'
    import DateTimePicker from '@/components/DateTimePicker'
    import momentTimezone from 'moment-timezone'

    export default {
        name: 'LeagueForm',
        components: { Alert, DateTimePicker },
        data: () => {
            return {
                showAlert: false,
                message: null,
                type: null,
                loading: false,
                mode: null,
                league: {},
                readOnly: false,
                clearable: true,
                startDate: null,
                startTime: null,
                dateRange: null,
                endDate: null,
                endTime: null,
                rules: [
                    v => !!v || 'Field is required'
                ]
            }
        },
        created() {
            this.mode = this.$route.params.mode
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.leagueId = this.$route.params.leagueId
            if (this.$route.params.leagueId) {
                leagueService.getLeague(this.$route.params.leagueId).then(resp => {
                    this.league = resp.data
                    if (this.league.startDate) {
                        this.startDate = momentTimezone(this.league.startDate).tz('Europe/Athens').format('YYYY-MM-DD').toString()
                    }
                    if (this.league.endDate) {
                        this.endDate = momentTimezone(this.league.endDate).tz('Europe/Athens').format('YYYY-MM-DD').toString()
                    }
                })
            }
        },
        methods: {
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            setStartDate(date) {
                this.startDate = date
            },
            setEndDate(date) {
                this.endDate = date
            },
            shouldDisableSubmit() {
                return false
            },
            createLeague() {
                this.loading = true
                leagueService.createLeague(this.league).then(leagueResponse => {
                    if (leagueResponse.id) {
                        this.leagueId = leagueResponse.id
                    }
                    this.loading = false
                    this.$router.push(`/leagues`)
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            editLeague() {
                this.loading = true
                leagueService.updateLeague(this.league.id, this.league).then((response) => {
                    this.loading = false
                    this.$router.push(`/leagues`)
                }).catch((error) => {
                    this.setAlert(error.response.data.message, 'error')
                    this.loading = false
                })
            },
            async onSubmit() {
                if (this.startDate) {
                    this.league.startDate = momentTimezone(this.startDate).tz('Europe/Athens').startOf('day').utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]').toString()
                }
                if (this.endDate) {
                    this.league.endDate = momentTimezone(this.endDate).tz('Europe/Athens').endOf('day').utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]').toString()
                }
                if (this.mode === 'edit') {
                    this.editLeague()
                } else if (this.mode === 'create') {
                    this.createLeague()
                }
            }
        }

    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.img-preview {
    max-width: 70%;
    margin: 0 auto;
}

.image-input {
    padding: 20px;
    width: 100%;
    border-style: groove;
    border: #D3D3D3 solid 2px;
    border-radius: 15px 50px;
    margin-top: 1vh;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
